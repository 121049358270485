<template>
  <div class="skills">
    <back-arrow to="/portfolio" />

    <div class="wrapper foreground blend-white-bg regular-padding">
      <h1>Skills</h1>
      <p class="left-align">This page is a bit outdated and will be updated soon. This page specifically lists what I believe I am capable of, my philosophy behind a skill and some concrete examples of how I employed/learned the skill.</p>

      <section>
        <h2>Game Design</h2>
        <div class="text-aside-combo">
          <p>I see Game Design as being equivalent to being a director of a movie. A game consists of many pieces and a Game Designer exists to bring these pieces together to a whole. 
          <br /><br />One of the main tasks of the Game Designer, therefore, is communication. This can be done through vocal communication or writing Game Design Documentation (GDD), but the key is to convey the ideas of a game to both other developers as well as players.
          <br /><br />Another important task then, is to decide what the ideas of a game are: what are the key aspects, what is the theme of the game, how do the visual artwork, music and sounds reinforce the theme, et cetera. This focuses a lot on identity: who is the player and how should the player feel, what fantasy should it fulfill?
          <br /><br />The third main task of a Game Designer is to have an understanding of game theory: what makes a game fun? Here, there are many answers possible, as there many different players. Personally, I am a fan of games that allow an expression of skill and where the player's choices are meaningful. I believe this knowledge comes from a mixture of psychology (what drives a human) and game analysis, by learning from other's successes and failures.
          <br /><br />
          My knowledge about Game Design comes from my studies, analyzing games that I have played, learning from colleagues as well as player feedback from projects I have designed.
          </p>
          <aside class="aside-links">
            <span style="display: block; margin-bottom: 10px">Favourite YouTube channels on game design:</span>  
            <ul> 
              <li><a href="https://www.youtube.com/user/Thefearalcarrot">Adam Millard - the architect of games.</a><br />A personal favourite game critic/game designer who nails breaking down what makes good games good.</li>
              <li><a href="https://www.youtube.com/user/McBacon1337">Game Maker's Toolkit</a><br />Another good YouTube channel with game analysis and game design</li>
            </ul>
          </aside>
        </div>
      </section>
      <section style="margin-top: 50px;">
        <h2>Level Design</h2>
        <div class="text-aside-combo">
          <p>I see Level Design as a subcategory of Game Design. Much that applies to Game Design, also applies to Level Design, but generally on a smaller scale. I say generally, because when one level is designed, the design of all other levels should also be kept in mind. This is, because anything done in one level often also affects other levels: whether this is the player's avatar becoming stronger due to loot/items/skills learned or due to the player improving his skill or knowledge simply by playing a level.
          <br /><br />Level Design is also, however, hard to define, as it is so dependent on what a 'level' is. And this can differ greatly from game to game. I think that a level can be seen somewhat as a game within a game. In Mario, the 'water level' has vastly different rules than the rest of the game has, for example. In the Dark Souls series, a level or area is not so strictly defined, as all areas are quite open. (Areas are 'blocked' mainly by the power of the player's avatar and the skill of the player). Despite this openness, the game's levels can still be seperated by the type of enemies encountered and the change of mechanics.
          <br /><br />I think that is the core of Level Design: distinctness. In games that have a level structure, I believe every level needs to have a distinct place within the game. Good level design will have players rethink their strategies or even explicitly punish strategies that worked for them in the past: this will re-engage the player, as the moment a player stops learning, the player will be less engaged.
          <br /><br />Above I only mentioned the gameplay aspects of Level Design. Another aspect of Level Design, however, is aesthetics. Level Design is often about piecing together art to make something that works within the game context. If the game is supposed to be realistic, then real thought should be given to why every object is in the place it is. If the game is supposed to be about physics, though, then it doesn't matter why there are so many crates and barrels and poorly built houses everywhere, ready to be tossed around and destroyed. Yet even then, it may be better if those objects seem to be there for a reason. Immersion is a big part of game design, so game environments should be made believable if at all possible.
          <br /><br />
          My knowledge about Level Design has come in the same way as my Game Design knowledge has come: learning from successes and mistakes of my own projects as well as other people's projects.
          </p>
          <aside class="aside-links">
            <span style="display: block; margin-bottom: 10px">Interesting videos on level design:</span>
            <ul>
              <li><a href="https://www.youtube.com/watch?v=5I4vD2S01d0">What Made Psychonauts Special | Game Maker's Toolkit</a><br />Analysis of a personal favourite game's level design.</li>
            </ul>
          </aside>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BackArrow from '../../components/BackArrow.vue'
//import {database} from '../firebase'
//import {tumblrClient} from '../tumblr'

export default {
  name: 'Skills',
  components: {BackArrow},
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  },
}
</script>


<style lang="scss">
  @import '@/scss/_variables.scss';

  .skills {
    display: flex;

  }
</style>
